.ps-nav-pill li a.active {
	color: var(--bg-c1) !important;
}

.primary-color {
	color: #e95039;
}

.ps-nav-pill li {
	color: var(--text-c1);
}

.ps-input {
	border-radius: 0.5em;
	background-color: var(--bg-c1);
}

.ps-sidebar-left ul li:hover {
	filter: brightness(0.75);
	background-color: var(--border-1);
}

.folders-sidebar li a {
	color: var(--text-c4) !important;
}

.folders-sidebar {
	background-color: var(--border-4) !important;
	color: var(--text-c4) !important;
}

.ps-sidebar ul li a {
	text-decoration-line: none;
	color: var(--text-c3);
}

a, ps-nav-links li a {
	text-decoration-line: none;
	color: var(--text-c3);
}

.ps-sidebar-left ul li.active {
	filter: brightness(0.57);
	/* background-color: var(--border-1); */
}

.ps-sidebar-container {
	border: none;
}

.ps-sidebar-left, 
.ps-sidebar-left ul li,
.ps-nav-links li.open .ps-nav-dropdown {
	border: none;
	color: #fff;
	background-color: var(--border-1);
}

.ps-navbar,
.ps-nav-links li.open .ps-nav-dropdown {
	border: none;
	background-color: var(--bg-c4);
}

.ps-page {
	background-color: var(--bg-c1);
}

.sidebar-bottom-buttons {
	height: 2em;
	width: 2em;
	border-radius: 50%;
	border: none
}

.nav-icons {
	color: #f65620;
	font-size: 1.4em;
	width: 15%;
}

.ps-sidebar-left {
	margin-top: 1em;
}

.ps-btn-primary {
	color: #fff;
	background-color: #f65720;
}

.ps-alert-nodata {
	background-color: rgb(169 135 123 / 31%);
	font-style: italic;
	padding: 1em;
	border-radius: 0.5em;
}

.details-header {
	padding: 2em 0.5em 0.5em 0.5em;
	font-size: 16px;
	margin-bottom: 0.5em;
	text-transform: uppercase;
	border-bottom: 2px solid var(--bg-shadow);
}

.ps-panel-header {
	background-color: var(--fg-c6);
	text-transform: uppercase;
	font-size: 18px;
	color: var(--text-c1);
	border-bottom: none;
	padding: 0.5em;
}

.contact-search-th {
	font-size: 16px;
	padding: 0.3em;
	white-space: nowrap;
	background-color: none;
}

.contact-search-row:hover {
	filter: drop-shadow(1px 1px 1px grey);
}

.ps-dialog-header,
.ps-dialog-footer {
	padding: 0.8em !important;
	color: #fff !important;
	background-image: linear-gradient(to right,#b62813, #861a09);
}

.search-results {
	max-height: 40em;
	min-width: 19em;
	max-width: 27em;
	overflow-y: scroll;
	position: absolute;
	z-index: 1;
	background-color: var(--bg-c2);
	border-radius: 0.5em;
	top: 7.3em;
}

.search-results li {
	cursor: pointer;
	list-style: none;
}

.effect2
{
	position: relative;
	-webkit-box-shadow: 0 10px 6px -6px var(--bg-shadow);
	-moz-box-shadow: 0 10px 6px -6px var(--bg-shadow);
	box-shadow: 0 10px 6px -6px var(--bg-shadow);
}

.query-container {
	display: flex;
	flex-direction: column;
	margin: 1em;
}

.query-header {
	display: flex;
	padding: 1.5em 0em;
	box-shadow: 1px 2px 4px 1px rgba(1, 1, 1, 0.2);
	background-color: var(--bg-c4);
	font-weight: bold;
}

.query-filters {
	display: flex; 
	gap: 1em;
	place-items: center;
}

.queries-list {
	flex: 1 0 auto;
	flex-direction: column;
	height: 80vh;
	overflow-y: scroll;
}

.query-item {
	display: flex;
	padding: 1em 0 0.5em 0;
	box-shadow: 1px 2px 4px 1px rgba(1, 1, 1, 0.2);
	margin-top: 0.5em;
	cursor: pointer;
}

.query-item:hover {
	background-color: var(--fg-c6);
}

.column {
	padding: 0 1em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.query-sort {
	display: flex;
	gap: 1em;
}

.sort-headers {
	padding: 0.5em 1em;
	text-align: center;
	height: 2.5em;
	min-width: 5em;
	border-top-right-radius: 0;
	border-top-left-radius: 0.5em;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0.5em;
}

.sort-inputs {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	height: 2.5em;
	border-left: none;
}

.bigger-btn {
	padding: 0.6em 1.5em;
}

.query_basic_info {
	background-color: var(--fg-c6);
	border-radius: 1em;
	padding: 1em;
}

.display_grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1em;
}
.display_grid_two {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1em;
}

.input_with_btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.ps-alert-nodata {
	text-align: center;
	width: 100%;
	display: block;
	line-height: 3em;
}

.contact {
	height: 28em;
	overflow-y: scroll;
	padding: 0.7em;
}
.ps-btn-default {
	border: none;
	background-color: var(--fg-c6);
}
.message-body {
	height: 20em !important;
}
.message-date {
	color: var(--border-1);
	font-style: italic;
	float: inline-end;
}

.message-collapse-info {
	background-color: var(--fg-c6);
	padding: 0.5em;
	border-top-left-radius: 0.5em;
	border-bottom-left-radius: 0.5em;
	font-size: 12px;
	margin-bottom: 1em;
}
.message-collapse-options {
	height: 10em;
	padding: 0.5em;
	background-color: var(--fg-c6);
	border-top-right-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
	font-size: 12px;
	text-align: center;
	margin-bottom: 1em;
}
.message-collapse-content {
	margin-bottom: 0.2em;
}
.message-collapes-status {
	border-radius: 0.3em 0.3em 0 0;
	text-align: center;
	padding: 0.3em;
}
.message-collapse-attachments {
	background-color: rgb(219, 170, 46);
	color: #fff;
	border-radius: 0.3em;
	margin-bottom: 0.2em;
	width: fit-content;
}

.message-channel-icon {
	font-size: 3em;
	background-color: var(--bg-c2);
}

.message-container {
	margin-bottom: 1em;
	border-radius: 1em;
	background-color: var(--bg-c2);
	padding: 1em;
}

/* SETUP PAGE */
.setup-container {
	background-color: var(--fg-c6);
	border-radius: 0.6em;
	text-align: center;
	height: 12em;
	margin-bottom: 1.5em;
}

.setup-btn {
	font-size: 3.5em;
}

.setup-page-headers {
	background-color: var(--bg-c4);
	border-radius: 0.4em;
	text-align: center;
	padding: 0.5em;
}

.setup-sub-header {
	border-bottom: 2px solid var(--fg-c2);
	font-size: 20px;
	display: flex;
	justify-content: space-between;
}

.toggle-btn {
	background: none;
	font-size: 24px;
	cursor: pointer;
	position: relative;
	top: 1em;
	color: var(--fg-c2);
}