/*NOTE: WORK IN PROGRESS*/
:root.theme-crm-light {
	--fg-c1: #000;
	--bg-c1: #fdfdfd;
	--text-c1: #4e4e4e;

	--fg-c2: #f01717;
	--bg-c2: white;
	--text-c2: #212a2e;

	--fg-c3: #4a90e2;
	--bg-c3: #cccccc;
	--text-c3: white;

	--fg-c4: rgb(230, 230, 230);
	--bg-c4: #e1e1e1;
	--text-c4: white;

	--fg-c5: #fdfdfd;
	--bg-c5: black;
	--text-c5: black;

	--fg-c6: #f7f7f7;
	--bg-c6: #acacac36;

	--border-1: #4e4e4e;
	--border-2: #a8a8a8;
	--border-3: #bebebe;
	--border-4: #a3a4a7;

	--bg-shadow: rgb(197, 193, 193);

	--fg-warning: #f0ad4e;
	--fg-danger: #c9302c;
	--fg-success: #5cb85c;
	--fg-default: #fff;
	--fg-primary: #3498db;

	--bg-warning: #f0ad4e;
	--bg-danger: #c9302c;
	--bg-success: #5cb85c;
	--bg-default: #fff;
	--bg-primary: #3498db;

	--fa-primary-color: #4e4f52;
	--fa-secondary-color: #8f96a1;

	--font-family: "Lato", sans-serif; 
}
